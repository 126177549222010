<template>
  <div class="recipeComponents">
    <b-table
      v-if="formRecipe.components.length"
      id="tabelaSkladnikow"
      small
      :items="formRecipe.components"
      :fields="fields"
    >
      <template #cell(name)="data">
        {{ data.item.name + " " + data.item.value + " " + data.item.shortname }}
      </template>

      <template v-if="formAction !== 3" #cell(status)="data">
        <b-button @click="removeFromCompTable(data.item.ID_REC, data.index)"
          >x</b-button
        >
      </template>
    </b-table>

    <div v-if="formAction !== 3">
      <b-form id="formComponents" @submit="onSubmitRecipeComponents">
        <div id="searchComponents">
          <multiselect
            v-model="selected"
            :options="componentsViewArray"
            selectLabel="Enter"
            placeholder="Wybierz składnik"
            label="NAME"
            track-by="NAME"
            :allow-empty="false"
            :hideSelected="true"
          ></multiselect>
        </div>
        <div id="weightValueComponents" class="divInFlex">
          <b-input-group>
            <template #append>
              <b-input-group-text id="weightComponents">
                {{ selected.SHORTNAME }}
              </b-input-group-text>
            </template>
            <b-form-input
              id="valueComponents"
              class="inputComponents"
              v-model="componentsMeasureValue"
              required
              type="number"
            ></b-form-input>
          </b-input-group>
        </div>

        <!-- <b-form-input
          id="valueComponents"
          v-model="rCompValue"
          required
          size="sm"
          type="number"
        ></b-form-input> -->

        <!-- jezeli jest id typu to generuj opis wagi skladnika -->
        <div class="divInFlex">
          <b-button id="butSubmit" type="submit" class="inputComponents"
            >Dodaj</b-button
          >
        </div>
      </b-form>
      <div>
        <!-- Using value -->
        <b-button v-b-toggle="'collapse-2'" class="m-1"
          >Edytuj składniki</b-button
        >
        <b-collapse id="collapse-2">
          <Components />
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import Components from "@/components/Components.vue";
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "RecipeComponents",
  components: {
    Multiselect,
    Components,
  },
  data: function () {
    return {
      componentsMeasureValue: null,
      componentsViewArray: [], // tablica widoczna dla uzytkownika
      selected: [],
      fields: [
        { key: "name", label: "Lista składników:" },
        { key: "status", label: "" },
      ],
    };
  },
  props: {
    formAction: null, // 1 new, 2 edit, 3 display,
  },
  created: function () {
    if (this.formAction === 3) {
      // test
    } else if (this.formAction === 2){
      this.$store.dispatch("rc/getComponents");
    } else if (this.formAction === 1) {
      this.$store.dispatch("rc/getComponents");
    }
  },
  computed: {
    ...mapState("rc",["idr","componentsArray"]),
    formRecipe: {
      get() {
        return this.$store.state.rc.recipeFormObject;
      },
      set(value) {
        this.$store.commit("rc/SET_RECIPE_FORM", value);
      },
    },
  },
  watch: {
    'formRecipe.components': function () {
      // odejmij rekordy z glownej listy
      var removeUsedComponents = this.formRecipe.components.map((item) => {
        return item.id_comp;
      });
      this.componentsViewArray = this.componentsArray.filter(
        (item) => !removeUsedComponents.includes(item.ID_COMP)
      );
    },
    componentsArray: function () {
      if (this.formRecipe.components.length > 1) {
        var removeUsedComponents = this.formRecipe.components.map((item) => {
          return item.id_comp;
        });
        this.componentsViewArray = this.componentsArray.filter(
          (item) => !removeUsedComponents.includes(item.ID_COMP)
        );
      } else {
        this.componentsViewArray = this.componentsArray;
      }
    },
  },
  methods: {
    onSubmitRecipeComponents(event) {
      event.preventDefault();
      this.addToRecTable();
    },
    // dodaj do tablicy
    addToRecTable: function () {
      if (this.selected != "") {
        this.formRecipe.components.push({
          name: this.selected.NAME,
          shortname: this.selected.SHORTNAME,
          value: this.componentsMeasureValue,
          id_comp: this.selected.ID_COMP,
          idr: this.idr,
        });
        this.selected = "";
        this.componentsMeasureValue = "";
      }
    },
    removeFromCompTable: function (val, valIndex) {
      //console.log(valIndex);
      //this.rRecCompRecords.splice(this.rRecCompRecords.indexOf(val),1);
      this.formRecipe.components.splice(valIndex, 1);
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
#tabelaSkladnikow {
  width: auto;
}
#formComponents {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}
#formComponents > div {
  padding-right: 20px;
  padding-bottom: 20px;
}
#valueComponents {
  width: 80px;
}
</style>
