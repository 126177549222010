<template>
  <div>
    <!-- Element to collapse -->
    <b-card>
      <b-form id="formEditComponents" @submit="onSubmitComponents">
        <b-form-group
          id="select-measure-group"
          label="Miara:"
          label-for="select-measure"
        >
          <b-form-select id="select-measure" v-model="componentsMeasureID" required>
            <b-form-select-option :value="null" disabled
              >-- Wybierz wagę --</b-form-select-option
            >
            <option
              v-for="rec in componentsMeasureArray"
              :key="rec.id"
              v-bind:value="rec.id_comp_type"
            >
              {{ rec.name }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group
          id="select-comp-category=group"
          label="Grupa:"
          label-for="select-comp-category"
        >
          <b-form-select
            id="select-comp-category"
            v-model="componentsCategoryID"
            required
          >
            <b-form-select-option :value="null" disabled
              >-- Wybierz grupę --</b-form-select-option
            >
            <option
              v-for="rec in componentsCategoryArray"
              :key="rec.id"
              v-bind:value="rec.id_comp_cat"
            >
              {{ rec.name }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group
          id="valueNewComp"
          label="Nazwa składnika:"
          label-for="newNameComponents"
        >
          <b-form-input
            id="newNameComponents"
            v-model="componentsName"
            required
            size="sm"
          ></b-form-input>
        </b-form-group>
        <b-button id="butSubmitComponents" type="submit">Dodaj</b-button>
      </b-form>
    </b-card>
  </div>
</template>


<script>
import { mapState } from "vuex";

export default {
  name: "Components",
  data: function () {
    return {
      componentsName: "",
      componentsMeasureID: "",
      componentsCategoryID: ""
    };
  },
  computed: {
    ...mapState("rc", ["componentsMeasureArray", "componentsCategoryArray"]),
  },
  created: function () {
    this.getComponentsMeasure();
    this.getComponentsCategory();
  },
  methods: {
    getComponentsMeasure: function() {
      this.$store.dispatch("rc/getComponentsMeasure");
    },
    getComponentsCategory: function () {
      this.$store.dispatch("rc/getComponentsCategory");
    },
    postComponents: function () {
      this.$store.dispatch("rc/postComponents", {
        componentsName: this.componentsName,
        componentsMeasureID: this.componentsMeasureID,
        componentsCategoryID: this.componentsCategoryID
      })
    },
    onSubmitComponents(event) {
      event.preventDefault();
      this.postComponents();
      this.$store.dispatch("rc/getComponents");

      // modyfikacja tej sekcji auto reset

      this.componentsName = "";
      this.componentsMeasureID = "";
      this.componentsCategoryID = "";
    }, 
  },
};
</script>