<template>
  <div class="Recipe">
    <div class="panelAddEdit">
      <b-card
        id="bcardRecipe"
        header-text-variant="white"
        header-tag="header"
        header-bg-variant="secondary"
        class="mb-0 text-justify pre-line"
      >
        <template #header>
          <h4>{{ formRecipe.name }}</h4>
        </template>

        <div id="RecNavButtons">
          <div v-if="formAction === 3">
            <!-- <b-button @click="editRecipe">Edytuj</b-button> -->
          </div>
          <div v-else-if="formAction === 2">
            <b-button @click="$router.go(-1)">Anuluj</b-button>
          </div>
        </div>

        <b-card-title>
          {{
            this.recipeCategoryArray.length > 0 && this.formRecipe.idc > 0
              ? this.recipeCategoryArray.find(
                  (d) => d.id_cat == this.formRecipe.idc
                ).name
              : ""
          }}
          <hr />
        </b-card-title>

        <b-card-body>
          <div v-if="formAction === 3">
            <b-card-text class="opis">
              {{ formRecipe.opis }}
            </b-card-text>

            <RecipeComponents :formAction="this.formAction" />
          </div>

          <div v-else>
            <b-form @submit="onSubmit" @reset="onReset">
              <b-form-group
                id="input-1-name"
                label="NAZWA:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="formRecipe.name"
                  required
                  placeholder="Wprowadź nazwę"
                >
                </b-form-input>
              </b-form-group>

              <div id="input-2-3">
                <b-form-group
                  id="input-2-category"
                  label="KATEGORIA:"
                  label-for="input-2"
                >
                  <b-form-select id="input-2" v-model="formRecipe.idc" required>
                    <b-form-select-option :value="null" disabled
                      >-- Wybierz kategorię --</b-form-select-option
                    >
                    <option
                      v-for="rec in recipeCategoryArray"
                      :key="rec.id"
                      v-bind:value="rec.id_cat"
                    >
                      {{ rec.name }}
                    </option>
                  </b-form-select>
                </b-form-group>

                <b-form-group
                  id="input-3-eaters"
                  label="LICZBA OSÓB:"
                  label-for="input-3"
                >
                  <b-form-input
                    id="input-3"
                    type="number"
                    v-model.number="formRecipe.eaters"
                    required
                    placeholder="Podaj liczbę"
                  ></b-form-input>
                </b-form-group>
              </div>

              <b-form-group
                id="input-4-describe"
                label="PRZYGOTOWANIE / OPIS:"
                label-for="input-4"
              >
                <b-form-textarea
                  id="input-4"
                  v-model="formRecipe.opis"
                  required
                  placeholder="Wprowadź opis"
                  rows="10"
                  max-rows="20"
                ></b-form-textarea>
              </b-form-group>

              <RecipeComponents :formAction="this.formAction" />

              <div id="buttonForm">
                <b-button type="submit" variant="primary">Zapisz</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </div>
            </b-form>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <b-modal id="bv-modal-example" hide-footer>
      <template #modal-title>Wystąpił błąd</template>
      <div class="d-block text-center">
        <h3 style="margin: auto">Dane nie zostały zapisane!</h3>
      </div>
      <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')"
        >Zamknij okno</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import RecipeComponents from "@/components/RecipeComponents.vue";
import { mapState } from "vuex";

export default {
  name: "Recipe",
  components: {
    RecipeComponents,
  },
  computed: {
    formRecipe: {
      get() {
        return this.$store.state.rc.recipeFormObject;
      },
      set(value) {
        this.$store.commit("rc/SET_RECIPE_FORM", value);
      },
    },
    ...mapState("rc", ["loadingStatus", "recipeCategoryArray", "idr"]),
  },
  // tym watchem nadpisuje dane jezeli sie zmienia
  props: {
    id: String,
    formAction: {
      type: Number,
      default: 3,
    },
  },
  created: function () {
    if (this.id != "") {
      this.$store.commit("rc/SET_RECIPE_IDR", this.id);
    }

    this.$store.dispatch("rc/getCategory");

    if (this.formAction === 3) {
      this.resetFormData();
      this.$store.dispatch("rc/getRecordById", this.idr);
    } else if (this.formAction === 2) {
      // Jeszcze nic nie robię...
    } else if (this.formAction === 1) {
      this.resetFormData();
    } else {
      //this.formRecipe = null;
    }
  },
  methods: {
    resetFormData() {
      this.$store.commit("rc/RESET_RECIPE_FORM");
    },
    postRecord() {
      this.$store.dispatch("rc/postRecipes", this.formRecipe);
    },
    editRecipe() {
      this.formAction = 2;
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.formAction === 1) {
        this.postRecord();
      } else {
        this.putRecord();
      }
      //alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.resetFormData();
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    // putRecordById: function () {
    //   if (this.idr !== undefined)
    //     //sprawdza czy jest id rekordu
    //     axios
    //       .put("/./api/recipe.php", {
    //         idr: this.idr,
    //         idc: this.form.idc,
    //         name: this.form.name,
    //         eaters: this.form.eaters,
    //         describe: this.form.opis,
    //       })
    //       .then((response) => {
    //         //console.log(response.data);

    //         console.log(response.status);
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.opis {
  white-space: pre-line;
}
#RecNavButtons {
  float: right;
  margin: auto;
}
#input-2-category {
  display: inline-block;
  margin-right: 2%;
  width: 49%;
}
#input-3-eaters {
  display: inline-block;
  width: 49%;
}
#buttonForm {
  margin-top: 40px;
}
#bcardRecipe {
  margin-bottom: 10%;
}
</style>
